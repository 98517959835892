// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'user'
  }, {
    prop: 'date'
  }, {
    prop: 'result'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }]
}
